import api from "./api";

export const getReportsAll = async(status?: string, date?: Date, plant?: string) => {
    const response = await api.get(`reports/listAll?status=${status ? status : ""}&date=${date ? date : ""}&plant=${plant ? plant : ""}`);
    return response.data;
};

export const getReportsAllPagination = async(status?: string, date?: Date, plant?: string, limit?: number, page?: number) => {
    const response = await api.get(`reports/listAllPagination?status=${status ? status : ""}&date=${date ? date : ""}&plant=${plant ? plant : ""}&limit=${limit ? limit : ""}&page=${page ? page : ""}`);
    return response.data;
};

export const getReportsAllAnt = async(status: string, plant: string, dateAnt: any) => {
    const response = await api.get(`reports/listAll?status=${status}&dateAnt=${dateAnt}&plant=${plant}`);
    return response.data;
};

export const getReportsOne = async(id: string) => {
    const response = await api.get(`reports/${id}`);
    return response.data;
};

export const putReportsOne = async(id: string, data: any) => {
    const response = await api.put(`reports/${id}`, data);
    return response.data;
};

export const postReportEnviationEmail = async (body: any) => {
    const response = await api.post(`reports/toSendReportsEmail/`, body);
    return response.data;
};
