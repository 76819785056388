/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import { getNotificationsAll, putMyNotifications, putNotificationsAll } from '../../../../app/services/notificationsService'
import { tempoPassado } from '../../../../app/utils/date'

type Props = {
  setSearchNotification: any
}

const HeaderNotificationsMenu = ({setSearchNotification} : Props) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [loadingPutNotifications, setLoadingPutNotifications] = useState(false);

  const [mySearchNotificationsAlarmes, setMySearchNotificationsAlarmes] = useState(false);
  const [mySearchNotificationsReports, setMySearchNotificationsReports] = useState(false);
  const [mySearchNotificationsSistema, setMySearchNotificationsSistema] = useState(false);

  const getNotifications = async () => {
    try {
      setLoadingNotifications(true)
      const myNotifications = await getNotificationsAll()
      setNotifications(myNotifications)

      const search_notification = myNotifications.find((notification: any) => notification.confirm_read === false || notification.confirm_read === null)
      
      const search_notification_alarmes = myNotifications.find(
        (notification: any) => (notification.confirm_read === false || notification.confirm_read === null) 
        && notification.type === "ALARME")

      const search_notification_reports = myNotifications.find(
        (notification: any) => (notification.confirm_read === false || notification.confirm_read === null) 
        && notification.type === "RELATORIOS")

      const search_notification_sistema = myNotifications.find(
        (notification: any) => (notification.confirm_read === false || notification.confirm_read === null) 
        && notification.type === "SISTEMA")

      if(search_notification){
        setSearchNotification(true)
      }else{
        setSearchNotification(false)
      }

      if(search_notification_alarmes){
        setMySearchNotificationsAlarmes(true)
      }else{
        setMySearchNotificationsAlarmes(false)
      }

      if(search_notification_reports){
        setMySearchNotificationsReports(true)
      }else{
        setMySearchNotificationsReports(false)
      }

      if(search_notification_sistema){
        setMySearchNotificationsSistema(true)
      }else{
        setMySearchNotificationsSistema(false)
      }

    } catch (error) {
      console.log(error)
    }finally{
      setLoadingNotifications(false)
    }
  }

  const putNotifications = async (id: string) => {
    try {
      setLoadingPutNotifications(true)
      const myBody = {
        confirm_read: true
      }
      const myNotifications = await putMyNotifications(id, myBody)
      setNotifications(myNotifications)
    } catch (error) {
      console.log(error)
    }finally{
      setLoadingPutNotifications(false)
      getNotifications()
    }
  }

  const putMyNotificationsAll = async () => {
    try {
      setLoadingPutNotifications(true)
      const myBody = {
        confirm_read: true,
        idsNotifications: notifications.map((notification) => notification.id)
      }
      const myNotifications = await putNotificationsAll(myBody)
      setNotifications(myNotifications)
    } catch (error) {
      console.log(error)
    }finally{
      setLoadingPutNotifications(false)
      getNotifications()
    }
  }

  const handleClickRead = (id: string) => {
    putNotifications(id)
  }

  const handleClickUpdateAll = () => {
    putMyNotificationsAll()
  }

  useEffect(() => {
    getNotifications();

    const intervalId = setInterval(() => {
      getNotifications();
    }, 300000);
  
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-390px w-lg-400px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top bg-secondary'
        //style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notificações <span className='fs-8 opacity-75 ps-2'>({notifications.length})</span>
        </h3>
  
        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              Alarmes
              {mySearchNotificationsAlarmes === true &&
              (<span style={{marginLeft: '0.5rem'}} className='bullet bullet-dot bg-primary h-6px w-6px translate-middle animation-blink' />)}
            </a>
          </li>
  
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              Relatórios
              {mySearchNotificationsReports === true &&
              (<span style={{marginLeft: '0.5rem'}} className='bullet bullet-dot bg-primary h-6px w-6px translate-middle animation-blink' />)}
            </a>
          </li>
  
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_3'
            >
              Sistema
              {mySearchNotificationsSistema === true &&
              (<span style={{marginLeft: '0.5rem'}} className='bullet bullet-dot bg-primary h-6px w-6px translate-middle animation-blink' />)}
            </a>
          </li>
        </ul>
      </div>
  
      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          {!loadingNotifications ? 
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications.map((alert, index) => {
              if(alert.type === "ALARME"){
                return (
                  <div key={`alert${index}`} className={`d-flex flex-stack py-4 ${alert.confirm_read === true ? "bg-light" : ""}  mb-1 mt-1`}>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px me-4'>
                        <span className={clsx('symbol-label', `bg-light-warning`)}>
                          {' '}
                          <KTSVG
                            path={`/media/icons/duotune/general/gen044.svg`}
                            className={`svg-icon-2 svg-icon-warning`}
                          />
                        </span>
                      </div>
                      
      
                      <div className='mb-0 me-2'>
                        <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                          {alert.title}
                        </a>
                        <div className='text-gray-400 fs-7'>{alert.description}</div>
                      </div>
  
                    </div>
    
                    <span className='badge badge-light fs-8'>{alert && alert.created_at ? tempoPassado(alert.created_at) : ""}</span>
  
                    <div>
                      <button
                        onClick={(event: any) => {
                          handleClickRead(alert.id)
                        }}
                        disabled={loadingPutNotifications === true || alert.confirm_read === true ? true : false}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        style={{marginLeft: '1.5rem'}}
                      >
                        <i className={`bi bi-eye-fill`} style={{fontSize: '1.2rem'}}></i>
                      </button>
                    </div>
                  </div>
                )
              }
            })}
          </div> 
          : <>Carregando.. </>}
  
          <div className='py-3 text-center border-top'>
            <a href="#" className='btn btn-color-gray-600 btn-active-color-primary' onClick={() => {handleClickUpdateAll()}}>
              Visualizar todos <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </a>
          </div>
        </div>

        <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
        {!loadingNotifications ? 
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications.map((alert, index) => {
              if(alert.type === "RELATORIOS"){
                return (
                  <div key={`alert${index}`} className={`d-flex flex-stack py-4 ${alert.confirm_read === true ? "bg-light" : ""}  mb-1 mt-1`}>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px me-4'>
                        <span className={clsx('symbol-label', `bg-light`)}>
                          {' '}
                          <KTSVG
                            path={`/media/icons/duotune/files/fil005.svg`}
                            className={`svg-icon-2 svg-icon-dark`}
                          />
                        </span>
                      </div>
                      
      
                      <div className='mb-0 me-2'>
                        <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                          {alert.title}
                        </a>
                        <div className='text-gray-400 fs-7'>{alert.description}</div>
                      </div>
  
                    </div>
    
                    <span className='badge badge-light fs-8'>{alert && alert.created_at ? tempoPassado(alert.created_at) : ""}</span>
  
                    <div>
                      <button
                        onClick={(event: any) => {
                          handleClickRead(alert.id)
                        }}
                        disabled={loadingPutNotifications === true || alert.confirm_read === true ? true : false}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        style={{marginLeft: '1.5rem'}}
                      >
                        <i className={`bi bi-eye-fill`} style={{fontSize: '1.2rem'}}></i>
                      </button>
                    </div>
                  </div>
                )
              }
            })}
          </div> 
          : <>Carregando.. </>}
  
          <div className='py-3 text-center border-top'>
            <a href="#" className='btn btn-color-gray-600 btn-active-color-primary' onClick={() => {handleClickUpdateAll()}}>
              Visualizar todos <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </a>
          </div>
        </div>

        <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
          {!loadingNotifications ? <div className='scroll-y mh-325px my-5 px-8'>
            {notifications.map((alert, index) => {
              if(alert.type === "SISTEMA"){
                return (
                  <div key={`alert${index}`} className={`d-flex flex-stack py-4 ${alert.confirm_read === true ? "bg-light" : ""}  mb-1 mt-1`}>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px me-4'>
                        <span className={clsx('symbol-label', `bg-light`)}>
                          {' '}
                          <KTSVG
                            path={`/media/icons/duotune/coding/cod001.svg`}
                            className={`svg-icon-2 svg-icon-dark`}
                          />
                        </span>
                      </div>
                      
      
                      <div className='mb-0 me-2'>
                        <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                          {alert.title}
                        </a>
                        <div className='text-gray-400 fs-7'>{alert.description}</div>
                      </div>
  
                    </div>
    
                    <span className='badge badge-light fs-8'>{alert && alert.created_at ? tempoPassado(alert.created_at) : ""}</span>
  
                    <div>
                      <button
                        onClick={(event: any) => {
                          handleClickRead(alert.id)
                        }}
                        disabled={loadingPutNotifications === true || alert.confirm_read === true ? true : false}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        style={{marginLeft: '1.5rem'}}
                      >
                        <i className={`bi bi-eye-fill`} style={{fontSize: '1.2rem'}}></i>
                      </button>
                    </div>
                  </div>
                )
              }
            })}
          </div> : <>Carregando.. </>}
  
          <div className='py-3 text-center border-top'>
            <a href="#" className='btn btn-color-gray-600 btn-active-color-primary' onClick={() => {handleClickUpdateAll()}}>
              Visualizar todos <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </a>
            {/* <Link
              to='/crafted/pages/profile'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              
            </Link> */}
          </div>
        </div>
  
        {/* <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='d-flex flex-column px-9'>
            <div className='pt-10 pb-0'>
              <h3 className='text-dark text-center fw-bolder'>Get Pro Access</h3>
  
              <div className='text-center text-gray-600 fw-bold pt-1'>
                Outlines keep you honest. They stoping you from amazing poorly about drive
              </div>
  
              <div className='text-center mt-5 mb-9'>
                <a
                  href='#'
                  className='btn btn-sm btn-primary px-6'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_upgrade_plan'
                >
                  Upgrade
                </a>
              </div>
            </div>
  
            <div className='text-center px-4'>
              <img
                className='mw-100 mh-200px'
                alt='metronic'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/1.png')}
              />
            </div>
          </div>
        </div> */}
  

      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
