import api from "./api";

export const postServicesOne = async (body: any) => {
    const response = await api.post(`service/`, body);
    return response.data.result;
};

export const getServicesAll = async (status?: string, plant?: string, unitBranch?: string, colaborator?: string) => {
    const response = await api.get(`service/listAll?status=${status}&plant=${plant ? plant : ""}&collaborator=${colaborator ? colaborator : ""}&unitBranch=${unitBranch ? unitBranch : ""}`,);
    return response.data;
};

export const getServicesQtd = async () => {
    const response = await api.get(`service/listAllQtd`,);
    return response.data;
};

export const getServiceOne = async (id: string) => {
    const response = await api.get(`service/${id}`);
    return response.data;
};

export const putServiceOne = async (id: string, body: any) => {
    const response = await api.put(`service/${id}`, body);
    return response.data;
};

export const deleteServiceOne = async (id: string) => {
    const response = await api.delete(`service/${id}`);
    return response.data;
};
