/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const user = useAuth()


  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra005.svg'
        //title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title='Painel de Controle'
        fontIcon='bi-app-indicator'
      />

      {user.currentUser?.role.slug !== "CLIENTE" &&(<SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Monitoramento'
        fontIcon='bi-archive'
        //icon='/media/icons/duotune/coding/cod009.svg'
        iconV2='bi bi-eye-fill'
      >
        <SidebarMenuItem to='/portals' title='Portais' hasBullet={true} />
        
      </SidebarMenuItemWithSub>)}


      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Relatórios'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil005.svg'
      >
        <SidebarMenuItem to='/consumidoras' title='Unidades (Consumidoras)' hasBullet={true} />
        <SidebarMenuItem to='/faturas' title='Faturas' hasBullet={true} />
        <SidebarMenuItem to='/relatorios' title='Relatórios' hasBullet={true} />
        
      </SidebarMenuItemWithSub>


      <SidebarMenuItemWithSub
        to='/storeServices'
        title='Gestão de Serviços'
        fontIcon='bi-archive'
        //icon='/media/icons/duotune/ecommerce/ecm006.svg'
        iconV2='bi bi-tools'
      >
        <SidebarMenuItem to='/storeServices/1' title='Solicitações de Serviços' hasBullet={true} />
        {(user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
          &&
          <SidebarMenuItem to='/storeServices/2' title='Ordem de Serviço' hasBullet={true} />
        )}
        
        {user.currentUser?.role.slug !== "CLIENTE" &&(<SidebarMenuItem to='/storeServices/4' title='Catálogo' hasBullet={true} />)}
        
        {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
          && user.currentUser?.role.slug !== "CLIENTE" &&
          (<SidebarMenuItem to='/storeServices/3' title='Calendário' hasBullet={true} />
        )}
      </SidebarMenuItemWithSub>


      {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
      &&
      user.currentUser?.role.slug !== "CLIENTE"  && (
          <SidebarMenuItem
            to='/proposalGenerator'
            icon='/media/icons/duotune/finance/fin003.svg'
            title="Gestão Comercial"
            fontIcon='bi-app-indicator'
          />
      )}

      {/* {user.currentUser?.role.slug !== "CLIENTE" &&(
        <SidebarMenuItem
          to='/storeServices/3'
          icon='/media/icons/duotune/general/gen014.svg'
          title="Calendário"
          fontIcon='bi-app-indicator'
        />
      )} */}

      {user.currentUser?.role.slug === "CLIENTE" &&(
        <SidebarMenuItem
          to='/integrador'
          //icon='/media/icons/duotune/finance/fin003.svg'
          title="Integrador"
          fontIcon='bi-app-indicator'
          iconV2='bi bi-hammer'
        />
      )}  

      <SidebarMenuItemWithSub
        to='/storeServices'
        title='Configurações'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/coding/cod001.svg'
      >
        <SidebarMenuItem to='/perfilSettings' title='Perfil' hasBullet={true} />
        
        {user.currentUser?.role.slug !== "CLIENTE" &&(
          <SidebarMenuItem to='/filiais' title='Unidades (Filiais)' hasBullet={true} />
        )}

        {user.currentUser?.role.slug !== "CLIENTE" &&(
          <SidebarMenuItem to='/clientes' title='Clientes' hasBullet={true} />
        )}

        {user.currentUser?.role.slug !== "CLIENTE" &&(
          <SidebarMenuItem to='/usuarios' title='Usuários' hasBullet={true} />
        )}

        {user.currentUser?.role.slug !== "CLIENTE" &&(
          <SidebarMenuItem to='/colaboradores' title='Colaboradores' hasBullet={true} />
        )}

      </SidebarMenuItemWithSub>


      {/* {user.currentUser?.role.slug !== "CLIENTE" &&(
        <SidebarMenuItem
          to='/portals'
          icon='/media/icons/duotune/coding/cod009.svg'
          title="Portais"
          fontIcon='bi-app-indicator'
        />
      )} */}
      




      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Unidades'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        {user.currentUser?.role.slug !== "FILIAL" && user.currentUser?.role.slug !== "CLIENTE" &&(
          <SidebarMenuItem to='/filiais' title='Filiais' hasBullet={true} />)
        }
        <SidebarMenuItem to='/consumidoras' title='Consumidoras' hasBullet={true} />
        
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItem
        to='/faturas'
        icon='/media/icons/duotune/files/fil001.svg'
        title="Faturas"
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItem
        to='/relatorios'
        icon='/media/icons/duotune/files/fil005.svg'
        title="Relatórios"
        fontIcon='bi-app-indicator'
      /> */}
{/* 
      {user.currentUser?.role.slug !== "CLIENTE"  && (
        <SidebarMenuItem
          to='/clientes'
          icon='/media/icons/duotune/communication/com005.svg'
          title="Clientes"
          fontIcon='bi-app-indicator'
        />
      )} */}

      {/* {user.currentUser?.role.slug !== "CLIENTE"  && (
        <SidebarMenuItem
          to='/usuarios'
          icon='/media/icons/duotune/communication/com006.svg'
          title="Usuários"
          fontIcon='bi-app-indicator'
        />
      )} */}

      

        {/* <SidebarMenuItem
          to='/perfilSettings'
          icon='/media/icons/duotune/coding/cod001.svg'
          title="Configurações"
          fontIcon='bi-app-indicator'
        /> */}

{/* 
  <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}


      {/* <SidebarMenuItem
        to='/templates'
        icon='/media/icons/duotune/files/fil001.svg'
        title="Templates"
        fontIcon='bi-app-indicator'
      /> */}


    {/* 
      { <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> } */}

      



      {/*--------------------- Estilos do template----------------------------*/}



{/*       
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      

      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>

      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
